import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';

export default {
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  mixins: [baseCrudMixin],
  data: () => ({
    entity: 'Loan',
    returnTo: 'Loans'
  }),
  methods: {
    getDependencies(data) {
      return [
        { entity: 'applicant' },
        { entity: 'loanType' },
        { entity: 'status' },
        { entity: 'reason' },
        { entity: 'delay' },
        { entity: 'debtStep' },
        { entity: 'reasonDenial' },
        { entity: 'withdrawalApplicant' },
        { entity: 'withdrawalPv' },
        { entity: 'referalMunicipality' },
        { entity: 'referalNoordzij' }
      ];
    },
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData
  }
};
