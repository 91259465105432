import DSESelect from '@/components/DSE/DSESelect';
import { validatorRequire } from '@/utils/validators';
import MDinput from '@/components/MDinput';
import { Checkbox, DatePicker, InputNumber } from 'element-ui';
import DossierHeaderCard from '@/pages/dossiers/DossierHeaderCard';
import TextArea from '@/components/DSE/TextArea';
const today = new Date().toISOString().substr(0, 10);

export function getFormDefinition(vue) {
  return {
    saveButtons: [true],
    header: form => {
      return {
        type: DossierHeaderCard,
        props: {
          dossier: form
        }
      };
    },
    dossierID: {
      type: MDinput,
      props: {
        caption: 'common.dossierID',
        readonly: true
      }
    },
    requestDate: {
      type: DatePicker,
      defaultValue: today,
      props: {
        caption: 'loan.requestDate',
        required: true,
        format: 'dd-MM-yyyy'
      },
      rules: [{ validator: validatorRequire }]
    },
    applicantID: {
      type: DSESelect,
      props: {
        caption: 'loan.applicant',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['applicant/isLoading'],
        options: vue.$store.getters['applicant/loadedItems']
      }
    },
    statusID: {
      type: DSESelect,
      props: {
        caption: 'loan.status',
        valueField: 'id',
        labelField: 'name',
        required: true,
        loading: vue.$store.getters['status/isLoading'],
        options: vue.$store.getters['status/loadedItems']
      },
      rules: [{ validator: validatorRequire }]
    },
    agreementStatus: {
      type: MDinput,
      props: {
        readonly: true,
        caption: 'loan.agreementStatus'
      }
    },
    debtStepID: {
      type: DSESelect,
      props: {
        caption: 'loan.debtStep',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['debtStep/isLoading'],
        options: vue.$store.getters['debtStep/loadedItems']
      }
    },
    explanationApplicant: {
      type: TextArea,
      props: {
        caption: 'loan.explanationApplicant',
        maxlength: 2000
      }
    },
    interview: {
      type: TextArea,
      props: {
        caption: 'loan.interview',
        maxlength: 4000
      }
    },
    plan: {
      type: TextArea,
      props: {
        caption: 'loan.plan',
        maxlength: 4000
      }
    },
    requirements: {
      type: TextArea,
      props: {
        caption: 'loan.requirements',
        maxlength: 4000
      }
    },
    payslipsYN: {
      type: Checkbox,
      props: {
        caption: 'loan.payslips'
      }
    },
    debtSecuritiesYN: {
      type: Checkbox,
      props: {
        caption: 'loan.debtSecurities'
      }
    },
    bankstatementsYN: {
      type: Checkbox,
      props: {
        caption: 'loan.bankstatements'
      }
    },
    bkrCheckYN: {
      type: Checkbox,
      props: {
        caption: 'loan.bKRCheck'
      }
    },
    intakeYN: {
      type: Checkbox,
      props: {
        caption: 'loan.intake'
      }
    },
    reasonRemark: {
      type: TextArea,
      props: {
        caption: 'loan.reasonRemark',
        maxlength: 4000
      }
    },
    withdrawalApplicantID: {
      type: DSESelect,
      props: {
        caption: 'loan.reasonWithdrawalApplicant',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['withdrawalApplicant/isLoading'],
        options: vue.$store.getters['withdrawalApplicant/loadedItems']
      }
    },
    withdrawalPvID: {
      type: DSESelect,
      props: {
        caption: 'loan.reasonWithdrawalPv',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['withdrawalPv/isLoading'],
        options: vue.$store.getters['withdrawalPv/loadedItems']
      }
    },
    referalMunicipalityID: {
      type: DSESelect,
      props: {
        caption: 'loan.reasonReferalMunicipality',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['referalMunicipality/isLoading'],
        options: vue.$store.getters['referalMunicipality/loadedItems']
      }
    },
    referalNoordzijID: {
      type: DSESelect,
      props: {
        caption: 'loan.reasonReferalNoordzij',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['referalNoordzij/isLoading'],
        options: vue.$store.getters['referalNoordzij/loadedItems']
      }
    },
    reasonDenialID: {
      type: DSESelect,
      props: {
        caption: 'loan.reasonDenial',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['reasonDenial/isLoading'],
        options: vue.$store.getters['reasonDenial/loadedItems']
      }
    },
    empty1: {
      type: MDinput,
      props: {
        caption: 'common.space',
        style: 'display: none'
      }
    },
    empty2: {
      type: MDinput,
      props: {
        caption: 'common.space',
        style: 'display: none'
      }
    },
    empty3: {
      type: MDinput,
      props: {
        caption: 'common.space',
        style: 'display: none'
      }
    },
    empty4: {
      type: MDinput,
      props: {
        caption: 'common.space',
        style: 'display: none'
      }
    },
    empty5: {
      type: MDinput,
      props: {
        caption: 'common.space',
        style: 'display: none'
      }
    },
    loanTypeID: {
      type: DSESelect,
      props: {
        caption: 'loan.loanType',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['loanType/isLoading'],
        options: vue.$store.getters['loanType/loadedItems']
      }
    },
    amount: {
      type: MDinput,
      props: {
        caption: 'loan.amount',
        type: 'currency'
      }
    },
    repaymentAmount: {
      type: MDinput,
      props: {
        caption: 'loan.repaymentAmount',
        type: 'currency'
      }
    },
    terms: {
      type: MDinput,
      key: 'terms',
      props: {
        caption: 'loan.terms',
        type: 'number',
        precision: 0,
        readonly: true
      }
    },
    interest: {
      type: MDinput,
      defaultValue: 0,
      props: {
        caption: 'loan.interest',
        type: 'percentage'
      }
    },
    reasonID: {
      type: DSESelect,
      props: {
        caption: 'loan.reason',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['reason/isLoading'],
        options: vue.$store.getters['reason/loadedItems']
      }
    },
    firstDate: {
      type: DatePicker,
      props: {
        caption: 'loan.firstDate',
        required: false,
        format: 'dd-MM-yyyy'
      }
    },
    thlDate: {
      type: DatePicker,
      key: 'thldate',
      props: {
        caption: 'loan.thlDate',
        format: 'dd-MM-yyyy',
        readonly: true
      }
    },
    prlDate: {
      type: DatePicker,
      props: {
        caption: 'loan.prlDate',
        format: 'dd-MM-yyyy'
      }
    },
    boardDate: {
      type: DatePicker,
      props: {
        caption: 'loan.dateToBoard',
        format: 'dd-MM-yyyy'
      }
    },
    id: {
      type: MDinput,
      props: {
        caption: 'loan.actNumber',
        readonly: true
      }
    },
    holidayMoneyPercentage: {
      type: InputNumber,
      props: {
        caption: 'loan.holidayMoney',
        min: 0,
        max: 100,
        step: 1,
        controlsPosition: 'right',
        size: 'small'
      }
    },
    profitSharingPercentage: {
      type: InputNumber,
      props: {
        caption: 'loan.profitSharing',
        min: 0,
        max: 100,
        step: 1,
        controlsPosition: 'right',
        size: 'small'
      }
    },
    thirteenthMonthPercentage: {
      type: InputNumber,
      props: {
        caption: 'loan.thirteenthMonth',
        min: 0,
        max: 100,
        step: 1,
        controlsPosition: 'right',
        size: 'small'
      }
    },
    oneTimeGrantPercentage: {
      type: InputNumber,
      props: {
        caption: 'loan.oneTimeGrant',
        min: 0,
        max: 100,
        step: 1,
        controlsPosition: 'right',
        size: 'small'
      }
    },
    delayID: {
      type: DSESelect,
      props: {
        caption: 'loan.delay',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['delay/isLoading'],
        options: vue.$store.getters['delay/loadedItems']
      }
    },
    delayDate: {
      type: DatePicker,
      props: {
        caption: 'loan.delayDate',
        format: 'dd-MM-yyyy'
      }
    }
  };
}
export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, loan) {
  const form = {
    ...loan
  };
  return form;
}
